<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>课程签到</span>
      </div>
      <div class="container" v-show="isSignin">
        <div>
          <div class="signinButton" @click="signin">
            <div>
              <img src="../../assets/img/me/signin.png" alt="" />
              <p>签到</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-signedin" v-show="!isSignin">
        <div>
          <div class="signinButton">
            <div>
              <img src="../../assets/img/me/signin.png" alt="" />
              <p>{{ message }}</p>
            </div>
          </div>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/layout.vue'
import { courseSignin, getCourseSigninState } from '@/api/home'

export default {
  components: {
    layout
  },
  data() {
    return {
      isSignin: false,
      message: ''
    }
  },
  created() {
    this.getCourseSigninState()
  },
  methods: {
  
    returnHome() {
      this.$router.push('/')
    },
    signin() {
      courseSignin({
        id: this.$route.query.id
      }).then((res) => {
        this.$toast.success(res.message)
      })
    },
    getCourseSigninState() {
      getCourseSigninState({
        id: this.$route.query.id
      }).then((res) => {
        console.log(res.message)
        if (res.code == 200) {
          this.isSignin = true
        } else {
          this.message = res.message
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  .van-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .container-signedin {
    margin-top: 10px;
    margin-left: 10px;
    width: 355px;
    height: 284px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .signinButton {
      text-align: center;
      width: 110px;
      height: 111px;
      background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
      border-radius: 50%;
      box-shadow: 0px 10px 10px 0px rgba(12, 162, 92, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: CENTER;
        color: #ffffff;
      }
    }
  }
  .container {
    margin-top: 10px;
    margin-left: 10px;
    width: 355px;
    height: 284px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .signinButton {
      width: 110px;
      height: 111px;
      background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
      border-radius: 50%;
      box-shadow: 0px 10px 10px 0px rgba(12, 162, 92, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: CENTER;
        color: #ffffff;
      }
    }
  }
}
</style>

